<template>
    <div>
        <product-detail-pc v-if="$responsive.is('pc')"></product-detail-pc>
        <product-detail-mobile v-if="$responsive.is('mobile')"></product-detail-mobile>
    </div>
</template>


<script>
    import ProductDetailPC from './product-detail-pc';
    import ProductDetailMobile from './product-detail-mobile';

    export default {
      components: {
        'product-detail-pc': ProductDetailPC,
        'product-detail-mobile': ProductDetailMobile,
      },
    }
</script>