<template>
    <div class="p-d-page mobile">
        <div class="p-d-page-content">
            <!-- 标题 -->
            <el-breadcrumb class="title">
                <el-breadcrumb-item>
                    <router-link to="/">{{$t('layout.homePage')}}</router-link>
                </el-breadcrumb-item>
                <el-breadcrumb-item>{{$t('layout.productsCenter')}}</el-breadcrumb-item>
            </el-breadcrumb>

            <!-- 产品菜单 -->
            <div class="product-menu hidden">
                <categories></categories>
            </div>

            <!-- 产品详情 -->
            <div class="product-detail">
                <!-- 产品图片 -->
                <div class="product-images">
                    <el-carousel ref="headerImageCarousel" :autoplay="true">
                        <el-carousel-item v-for="hi in productHeaderImages" v-bind:key="hi">
                            <a class="header-img-item" :href="hi" data-lightbox="product-detail-imgs">
                                <img v-bind:src="$resources.autoTransform(hi)" />
                            </a>
                        </el-carousel-item>
                    </el-carousel>
                </div>

                <!-- 产品介绍，富文本 -->
                <div class="product-introduce">
                    <span class="title">产品介绍</span>
                    <div v-html="productIntroduce" class="introduce-text"></div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>

    // import AspectRatio from '../../components/AspectRatio';
    import ProductCategories from './product-categories';

    export default {
      components: {
        // 'aspect-ratio': AspectRatio,
        'categories': ProductCategories,
      },

      data() {
        return {

          categories: [],

          productId: null,
          product: null,
          productHeaderImages: [],
          productIntroduce: '',
        }
      },

      created() {
        window.page = this;
        this.productId = this.$route.query.productId;
        if (this.productId == null) {
          this.$router.replace('/product');
          return;
        }

        const loading = this.$loading();
        Promise.all([
          this.loadCategories(),
          this.loadProduct(),
        ]).then(() => loading.close());

        // 设置灯箱参数
        lightbox.option({
          'showImageNumberLabel': false,
        })
      },

      watch: {
        $route(newValue, oldValue) {
          if (newValue.query.productId !== this.productId) {
            this.productId = newValue.query.productId;
            this.loadProduct();
          }
        }
      },

      methods: {

        loadCategories() {
          return this.$ajax.get('/categories/types').then(response => {
            if (response.code !== 100) {
              return;
            }

            const types = response.data;
            const categories = [];
            categories.push({
              id: 'all',
              label: this.$t('products.allProduct'),
              children: [],
            });
            types.forEach(t => {
              const categoryName = this.$i18n.locale === 'zh-CN' ? t.zhCategoryName : t.enCategoryName;
              let c = categories.find(c => c.id === categoryName);
              if (!c) {
                c = {
                  id: categoryName,
                  label: categoryName,
                  children: [],
                };
                categories.push(c);
              }

              c.children.push({
                id: t.typeId,
                label: this.$i18n.locale === 'zh-CN' ? t.zhName : t.enName,
              });
            });

            this.categories = categories;
          });
        },

        loadProduct() {
          return this.$ajax.get('/item/' + this.productId).then(response => {
            if (response.code !== 100) {
              this.$router.go(-1);
              return;
            }

            this.product = response.data;
            this.productHeaderImages = this.product.headAttachments.map(ha => ha.url);
            this.productIntroduce = this.$i18n.locale === 'zh-CN' ? response.data.zhIntro : response.data.enIntro;

          });
        },

        onTypeClick(node) {
          // 非叶子节点点击不处理
          if (node.children && node.children.length > 0) {
            return;
          }
          if (node.id === 'all') {
            this.$router.push({
              path: '/product/product-center',
            });
          } else {
            this.$router.push({
              path: '/product/product-center',
              query: {
                typeId: node.id
              }
            });
          }
        },

      }
    }
</script>


<style lang="scss">
    .p-d-page.mobile {
        background-color: var(--white);

        .p-d-page-content {
            width: 100%;
            max-width: 1128px;
            margin: 0 auto;

            display: flex;
            flex-direction: column;
            justify-content: center;

            padding-bottom: 20px;

            .el-breadcrumb {
                margin-left: 20px;
                padding: 20px 0;
            }

            .product-detail {
                flex: 1;
                max-width: 800px;

                .product-images {
                    margin-top: 20px;

                    .header-img-item {
                        width: 100%;
                        height: 100%;

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                        }
                    }

                }

                .product-introduce {
                    margin-top: 30px;
                    padding-left: 20px;
                    padding-right: 20px;

                    .title {
                        font-size: 26px;
                        font-weight: 700;
                    }
                    .title:after {
                        content: '';
                        display: block;
                        width: 180px;
                        height: 2px;
                        background-color: var(--gray);
                    }

                    .introduce-text {
                        margin-top: 20px;
                    }
                }
            }
        }
    }

</style>
